<app-window [title]="title">
    <form role="form" id="detailForm">
        <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item">
                <a class="nav-link" [class.active]="tab==1" (click)="tab=1" >
                    <i class="fas fa-tasks"></i><span class="d-none d-sm-inline-block ml-2">Dettagli</span></a>
            </li>
            <li class="nav-item">
                <a class="nav-link" [class.active]="tab==2" (click)="tab=2" >
                    <i class="fas fa-clipboard"></i><span class="d-none d-sm-inline-block ml-2">Annotazione <span *ngIf="record.customfields!=undefined && record.customfields.length>0"  class="badge badge-danger ml-1">{{record.customfields.length}}</span></span></a>
            </li>
            <!--<li class="nav-item">
                <a class="nav-link" [class.active]="tab==3" (click)="tab=3" >
                    <i class="fas fa-map"></i><span class="d-none d-sm-inline-block ml-2">Ubicazione <span *ngIf="record.latitude==0" class="badge badge-warning"><small>NO GPS</small></span></span></a>
            </li>-->
            <li class="nav-item">
                <a class="nav-link" id="profile-tab" [class.active]="tab==4" (click)="tab=4" >
                    <i class="fas fa-euro-sign"></i><span class="d-none d-sm-inline-block ml-2">Documenti e Pagamenti</span></a>
            </li>
            <li class="nav-item" *ngIf="missingValuesCount>0" >
                <a class="nav-link" [class.active]="tab==5" (click)="tab=5">
                    <i class="fas fa-puzzle-piece"></i><span class="d-none d-sm-inline-block ml-2">Completamento dati <span  class="badge badge-danger ml-1">{{missingValuesCount}}</span></span>
                </a>
            </li>
            

            <li class="nav-item" *ngIf="record.user_action">
                <a class="nav-link" [class.active]="tab==6" (click)="tab=6">
                    <i class="fas fa-puzzle-piece"></i><span class="d-none d-sm-inline-block ml-2">Azioni dell'operatore</span>
                </a>
            </li>
            
            <li class="nav-item" *ngIf="record.id>0" >
                <a class="nav-link" [class.active]="tab==7" (click)="tab=7">
                    <i class="fas fa-list"></i><span class="d-none d-sm-inline-block ml-2">Log</span>
                </a>
            </li>
            

        </ul>
        <div class="p-2" >
            <div class="tab-content" id="myTabContent">
                <!-- DETTAGLI -->
                <div class="tab-pane fade show active" *ngIf="tab==1" >
                    <div class="row">
                        <div class="col-lg-9">
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="alert alert-danger" *ngIf="!checkOpeningTime()">
                                       <i class="fa fa-exclamation-triangle"></i>&nbsp;L'esercizio potrebbe essere chiuso!
                                    </div>

                                    <div class="alert alert-danger" *ngIf="record.address=='' || record.latitude==0 || record.city=='' || record.longitude==''">
                                        <i class="fa fa-exclamation-triangle"></i>&nbsp;L'attività non è correttamente ubicata!
                                    </div>
                                </div>
                                

                                <div class="col-sm-12" *ngIf="record.phase==4">
                                    <div class="alert alert-info">
                                        Attività rischedulata al {{record.reschedule | date:"d/M/Y"}}
                                        <br/>
                                        <small>
                                            Note: {{record.note_reschedule}}
                                        </small>
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="form-group">
                                        <label>Data</label>
                                        <div class="input-group input-group-sm">
                                            <input class="form-control form-control-sm" type="date"  name="date"  [(ngModel)]="record.date"  />
                                            <div class="input-group-append" *ngIf="id>0">
                                                <input class="form-control form-control-sm" type="text"  name="time"  [(ngModel)]="record.time" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-3">
                                    <div class="form-group">
                                        <app-type [id_type]="record.id_type"  (onTypeSelected)="type_activity_selected=$event;" [table]="'activities'" [record]="record" ></app-type>
                                    </div>
                                </div>
                                <div class="col-sm-3">
                                    <div class="form-group">
                                        <label>Fase</label>
                                        <select class="form-control form-control-sm" [(ngModel)]="record.phase" name="phase" #phase="ngModel" required>
                                            <option value="0">Da concordare</option>
                                            <option value="1">In corso</option>
                                            <option value="2">Conclusa</option>
                                            <option value="3">Confermata</option>
                                            <option value="4">Rischedulata</option>
                                        </select>
                                        
                                        <div *ngIf="phase.invalid && (phase.dirty || phase.touched)" class="alert alert-danger">
                                            <div *ngIf="phase.errors.required">Campo obbligatorio.</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-2">
                                    <div class="form-group">
                                        <label>Priorità</label>
                                        <select class="form-control form-control-sm" [(ngModel)]="record.priority" name="priority" #priority="ngModel" required>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-sm-12">
                                    <div class="form-group">
                                        <label>Anagrafica</label>
                                        <div class="small-box bg-light mb-0">
                                            <app-selectfieldcustom (OnExplore)="selectAddress()" (OnRecordSelected)="OnSelectAddress($event)" (OnRecordView)="OnViewAddress($event)"
                                                [showViewButton]="isModuleEnabled('address')"
                                                [showSearchButton]="isModuleEnabled('address')"
                                                [recordSelected]="record.addressItem"
                                                [service]="addressesService"
                                                [fieldRender]="'name'"
                                                [class]="'form-control form-control-sm'"></app-selectfieldcustom>
                                            <div class="p-1" *ngIf="record.addressItem && record.addressItem.id>0">
                                                <small>
                                                    <div class="row">
                                                        <div class="col-lg-6">
                                                            <small>
                                                                <ng-container *ngIf="record.addressItem.address || record.addressItem.city">
                                                                    <b>INDIRIZZO:</b><br>
                                                                    {{record.addressItem.address}}<br>
                                                                    {{record.addressItem.zip}} {{record.addressItem.city}} {{record.addressItem.country}}<br>
                                                                </ng-container>
                                                                <span *ngIf="record.addressItem.tax_code">COD. FISC.: {{record.addressItem.tax_code}}<br></span>
                                                                <span *ngIf="record.addressItem.vat_number">P.IVA: {{record.addressItem.vat_number}}</span>
                                                            </small>
                                                        </div>
                                                        <div class="col-lg-6 border-left" *ngIf="record.addressItem.contacts.length > 0">
                                                            <small>
                                                                <b>CONTATTI:</b><br>
                                                                <span *ngFor="let c of record.addressItem.contacts">{{c.value}}<br></span>
                                                            </small>
                                                        </div>
                                                    </div>
                                                </small>
                                                <div class="icon"><i class="fa fa-user"></i></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12">
                                    <app-cities name="city"  [enable_geocoding]="false" [latitude]="record.latitude" [longitude]="record.longitude" [address]="record.address" [country]="record.country" [city]="record.city"  [zip]="record.zip" (onEntrySelected)="record.address=$event.address;record.city=$event.city;record.country=$event.country;record.zip=$event.zip;"></app-cities>
                                    <div class="row mt-2">
                                        <div class="col-5">
                                            <div class="form-group row">
                                                <div class="col-5"><label>Latitudine</label></div>
                                                <div class="col-7">
                                                    <div class="input-group">
                                                        <input type="text" class="form-control form-control-sm" name="city" placeholder=" ..." [(ngModel)]="record.latitude" (ngModelChange)="verifyCoordinate()" >
                                                        <div class="input-group-append bg-light">
                                                            <div class="input-group-text"  title="Puoi Incollare direttamente qui le coordinate nel formato: LAT, LONG">
                                                                <span class="fas fa-info"></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-5">
                                            <div class="form-group row">
                                                <div class="col-5 text-right"><label>Longitudine</label></div>
                                                <div class="col-7">
                                                    <input type="text" class="form-control form-control-sm" name="zip" placeholder=" ..." [(ngModel)]="record.longitude" (ngModelChange)="verifyCoordinate()" >
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-2">
                                            <button  class="btn btn-sm btn-default btn-block" (click)="openSearchGPS()" ><i class="fas fa-search-location mr-2"></i>Trova</button>                                    
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <label>Elemento collegato</label>
                                            <div class="p-2 border">
                                            <app-reference #referenceRecord [minimazed]="false" [showConnectBtn]="true" (recordConnected)="connectActivity($event)" [type_filter]="type_activity_selected" [reference]="record.reference" [table]="record.table" [mode]="'detail'"></app-reference>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6" *ngIf="record.opening_time">
                                    <label>Orari di apertura</label>
                                    <app-timesheet [edit]="false" [record]="record" [fieldname]="'opening_time'" [daynumber]="getDay()"></app-timesheet>
                                </div>
                                <div class="col-sm-12">
                                    <div class="form-group">
                                        <label>Descrizione</label>
                                        <textarea class="form-control form-control-sm" rows="1" onblur="this.rows=3;" onfocus="this.rows=4;"  name="description" [(ngModel)]="record.description"></textarea>
                                    </div>
                                </div>
                                <div class="col-sm-12">
                                    <div class="form-group">
                                        <label>Operatore</label>
                                        <select class="form-control form-control-sm" [(ngModel)]="record.id_user" name="id_user" >
                                            <ng-container *ngFor="let u of users">
                                                <option *ngIf="u.enabled" [ngValue]="u.id">{{u.username}}</option>
                                            </ng-container>
                                        </select>
                                    </div>
                                </div>
                                
                                <!--<div class="col-sm-5">
                                    <div class="form-group">
                                        <label>Agenda</label>
                                        <div class="input-group">
                                            <p class="border p-2" [(ngModel)]="record.id_table" *ngIf="record.id_deadline>0"><span  ><strong>{{record.deadline.date}}</strong><br/><i>{{record.reference.reference}}</i><br/><small>{{record.reference.address}}, {{record.reference.city | uppercase}}</small></span></p>
                                            
                                            <div class="callout callout-info" *ngIf="record.id_deadline==0">
                                                <h5><i class="icon fas fa-info mr-2"></i>Nota:</h5>
                                                <i>Nessun voce di agenda selezionata</i>
                                            </div>
                                            <div class=" ml-2 ">
                                                <button data-toggle="modal" data-target="#modal" class="btn btn-default bg-blue" (click)="openModal('DeadlinesComponent','Seleziona la voce di agenda','id_table','deadlines')">
                                                    <span class="d-none d-sm-inline-block">Seleziona</span><i class="nav-icon fas fa-cash-register ml-2"></i></button>
                                            </div>
                                        </div> 
                                    </div>
                                </div>-->
                                
                                <div class="col-sm-12">
                                    <div class="form-group">
                                        <label>Note</label>
                                        <div class="input-group">
                                            <textarea  class="form-control form-control-sm" rows="1" onblur="this.rows=1;" onfocus="this.rows=3;"  name="note" [(ngModel)]="record.note" placeholder="..."></textarea>
                                        </div> 
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 pl-lg-0">
                            <div class="card mt-2 bg-light">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <div class="form-group">
                                                <span>Importo</span>
                                                <div class="input-group input-group-sm">
                                                    <div class="input-group-prepend ">
                                                        <span class="input-group-text">&euro;</span>
                                                    </div>
                                                    <input type="number" class="form-control form-control-sm"  min="0" max="999999999" step="0.01" name="amount" placeholder="..." [(ngModel)]="record.amount" (change)="calculateGross()" (keyup)="calculateGross()">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12">
                                            <div class="form-group">
                                                <span>IVA</span>
                                                <div class="input-group input-group-sm">
                                                    <div class="input-group-prepend ">
                                                        <span class="input-group-text">%</span>
                                                    </div>
                                                    <input type="number" class="form-control form-control-sm"  min="0" max="100" step="1" name="tax" placeholder="..." [(ngModel)]="record.tax" >
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12">
                                            <div class="form-group">
                                                <span>Totale</span>
                                                <div class="input-group input-group-sm">
                                                    <div class="input-group-prepend ">
                                                        <span class="input-group-text">&euro;</span>
                                                    </div>
                                                    <input type="number" class="form-control form-control-sm"  min="0" max="999999999" step="0.01" name="gross" placeholder="..." [(ngModel)]="record.gross" (change)="calculateNet()" (keyup)="calculateNet()">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12">
                                            <div class="form-group">
                                                <span>Incassato</span>
                                                <div class="input-group input-group-sm">
                                                    <div class="input-group-prepend ">
                                                        <span class="input-group-text">&euro;</span>
                                                    </div>
                                                    <input type="number" class="form-control form-control-sm"  min="0" max="999999999" step="0.01" name="paid" placeholder="..." [(ngModel)]="record.paid" >
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12">
                                            <div class="form-group">
                                                <span>Metodo di pagamento</span>
                                                <div class="input-group input-group-sm">
                                                    <input type="text" class="form-control form-control-sm" name="method_payment"  [(ngModel)]="record.method_payment" >
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12">
                                            <div class="form-group">
                                                <span>Causale</span>
                                                <div class="input-group">
                                                    <input type="text" class="form-control form-control-sm" min="0" step="0.01" name="paid_description" placeholder="..." [(ngModel)]="record.paid_description " >
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12">
                                            <div class="btn-group btn-group-sm w-100 mb-2" >
                                                <button type="button" class="btn btn-default border btn-sm" [class.alert-success]="record.paid_type==1" (click)="record.paid_type=1">Pagato</button>
                                                <button type="button" class="btn btn-default border btn-sm" [class.alert-info]="record.paid_type==2" (click)="record.paid_type=2">Omaggio</button>
                                                <button type="button" class="btn btn-default border btn-sm" [class.alert-warning]="record.paid_type==3" (click)="record.paid_type=3">Rischedula</button>
                                            </div>
                                            <ng-container *ngIf="record.paid_type==3">
                                                <div class="form-group">
                                                    <span>Data del pagamento</span>
                                                    <input class="form-control form-control-sm" type="date" name="reschedule_payment" [(ngModel)]="record.reschedule_payment">
                                                </div>
                                                <div class="form-group">
                                                    <span>Note</span>
                                                    <input class="form-control form-control-sm" type="text" name="note_reschedule_payment" [(ngModel)]="record.note_reschedule_payment">
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- ANNOTAZIONE -->
                <div class="tab-pane fade show active" *ngIf="tab==2" >
                    <div class="row">
                        <div class="col-12">
                            <div class="alert alert-info text-center" *ngIf="record.id_form>0">
                                È stato compilato un modulo<br>
                                <button class="btn btn-sm btn-info mt-2" (click)="openForm()">Vedi modulo</button>
                            </div>
                        </div>
                    </div>
                    <!-- Extra fields -->
                    <app-customfields [list]="record.customfields" [table]="'activities'" [id_table]="record.id" [condition_field]="'id_type'" [condition_id]="record.id_type" ></app-customfields>
                </div>
                <!-- COMPLETAMENTO DATI -->
                <div class="tab-pane fade show active" *ngIf="tab==5" >
                    <app-missing-values [list]="record.missingValues"></app-missing-values>
                </div>
                <!-- PAGAMENTO -->
                <div class="tab-pane fad show active" *ngIf="tab==4" >
                    <div class="row">
                        <h5>Elenco documenti collegati</h5>
                        <div class="col-sm-12 border-top mt-3 pt-2">
                            <app-documentlist [filterbox]="false" [type]="0" [id_table]="record.id" [table]="'activities'" [mode]="'embedded'" *ngIf="id != 0" (ids)="ids_documents=$event"></app-documentlist>
                      
                        </div>
                        <div class="col-sm-12 border-top mt-3 pt-2">
                            <h5>Elenco movimenti collegati</h5>
                            <app-cash-flow #cashflow  [deleteInRow]="true" [filterbox]="false" [id_table]="record.id" [table]="'activities'" [mode]="'embedded'" *ngIf="id != 0"></app-cash-flow>
                            
                            <ng-container *ngIf="record.id_cashflow > 0">
                                <div class="input-group">
                                    <!--<button class="btn btn-sm btn-secondary" (click)="openCashFlow(record.id_cashflow);">Vedi movimento</button>-->
                                    <button class="btn btn-sm btn-danger mr-2" (click)="record.id_cashflow=0">Rimuovi collegamento</button>
                                </div>
                            </ng-container>
                            
                            <ng-container *ngIf="!record.id_cashflow || record.id_cashflow == 0">
                                <div class="input-group">
                                    <button class="btn btn-primary btn-sm" (click)="addPayment()">Registra pagamento</button>
                                    <button class="btn btn-primary btn-sm ml-2" (click)="LinkPayment()">Collega pagamento</button>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
                <!-- UBICAZIONE -->
                <div class="tab-pane fade show active" *ngIf="tab==3">
                   
                </div>
                <!-- AZIONI DELL'OPERATORE -->
                <div class="tab-pane fade"  *ngIf="record.user_action && tab==6">
                    
                    <div class="row">
                        <div class="col-12" *ngIf="record.user_action.action=='substitution'">
                            <user-action-substitution [mode]="'embedded'" [activity]="record" [item]="record.reference"></user-action-substitution>
                            
                        </div>

                        <div class="col-12" *ngIf="record.user_action.action=='retired'">
                            <user-action-retired [mode]="'embedded'" [activity]="record" [item]="record.reference"></user-action-retired>
                      
                        </div>
                    </div>
                </div>
                <!-- LOG-->
                <div class="tab-pane fade" *ngIf="record.id>0 && tab==7">
                    <app-activities-logs [id_activity]="record.id" ></app-activities-logs>
                    
                </div>

            </div>
            
        </div>
    </form>
    <app-toolbarmodel [model]="this"></app-toolbarmodel>
</app-window>


<ng-template #modalSelectType>
    <div class="modal-header bg-light">
        <b>Nuova attività</b>
        <div class="card-tools">
            <button type="button" class="btn btn-tool" (click)="closeModalSelectType()" >
                <i class="fas fa-times"></i>
            </button>
        </div>
    </div>
    <div class="modal-body">
        <button *ngFor="let t of type_activities" class="btn bg-olive btn-block"      (click)="record.id_type=t.id;closeModalSelectType();type_activity_selected=t;"                             >{{t.name}}</button>
        
    </div>
</ng-template>