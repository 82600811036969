<div class="row">
    <div class="col-12" style="max-height:60vh; overflow:auto">
        <ul class="list-group list-group-flush" >
            <li *ngFor="let f of files;let i=index" class="list-group-item">
                <div class="row border-bottom pb-2">
                    <div class="col-9">
                        <ng-container *ngIf="!f['edit']">
                            <i  title="{{getExtension(f) | uppercase }}" class="text-gray fas fa-2x fa-file fa-file-{{getExtension(f)}}"
                                [class.fa-file-zipper]="getExtension(f)=='zip' || getExtension(f)=='rar'"
                                [class.fa-file-image]="getExtension(f)=='bmp' || getExtension(f)=='jpg' || getExtension(f)=='png'"
                                [class.fa-file-video]="getExtension(f)=='mov' ||getExtension(f)=='avi' || getExtension(f)=='mp4'"
                                [class.fa-file-audio]="getExtension(f)=='wav' ||getExtension(f)=='mp3' || getExtension(f)=='wma'"
                                [class.fa-file-word]="getExtension(f)=='doc' || getExtension(f)=='docx'"
                                [class.fa-file-excel]="getExtension(f)=='xls' || getExtension(f)=='xlsx'"
                                [class.fa-file-powerpoint]="getExtension(f)=='ppt' || getExtension(f)=='pptx'"
                                [class.fa-file-code]="getExtension(f)=='xml'"
                                [class.fa-file-signature]="getExtension(f)=='p7m'">
                            </i>&nbsp;
                            <span title="{{f.name}}" class="text-truncate d-inline-block" style="width:90%" >{{f.name}}
                                <i (click)="f['edit']=true" title="Rinomina" class="fa fa-pencil-alt pointer ml-2"></i></span>
                        </ng-container>
                        <ng-container  *ngIf="f['edit']">
                            <div class="input-group input-group-sm">
                                <input type="text" class="form-control form-control-sm" autofocus value="{{f.name}}" name="{{i}}_{{f.file}}" [(ngModel)]="f.name" (focusout)="f['edit']=false" (key.enter)="f['edit']=false"/>
                                <div class="input-group-append">
                                    <span class="btn btn-sm" (click)="f['edit']=false" title="Conferma"><i class="fas fa-check text-success"></i></span>
                                </div>
                            </div>
                            <span  title="{{config.serverUrl}}{{f.file}}" class="text-xs text-truncate d-inline-block" style="width:90%">Percorso file: {{config.serverUrl}}{{f.file}}</span>
                        </ng-container>
                    </div>
                    <div class="col-3 text-right">
                        <a class="btn btn-info btn-sm ml-1" title="Visualizza" (click)="downloadFile(f)"><i class="fa fa-eye"></i></a>
                        <!-- <a class="btn btn-secondary btn-sm ml-1"   title="Rinomina" (click)="f.edit=!f.edit"><i class="fa fa-pencil-alt"></i></a> -->
                        <a class="btn btn-danger btn-sm ml-1"  title="Elimina" (click)="removeFile(f)"><i class="fa fa-trash"></i></a>
                    </div>
                </div>
            </li>
        </ul>
    </div>
    <div class="col-12 text-center mt-1">
        <app-uploadfile [directory]="directory" (fileuploaded)="this.files.push($event);"></app-uploadfile>
    </div>
</div>