<section class="content">
        <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item">
                <a class="nav-link" [class.active]="tab==1" (click)="tab=1">
                    <i class="fas fa-list"></i><span class="d-none d-sm-inline-block ml-2">Lista</span></a>
            </li>
            <!--
            <li class="nav-item">
                <a class="nav-link" [class.active]="tab==3" (click)="tab=3">
                    <i class="fas fa-map"></i><span class="d-none d-sm-inline-block ml-2">Mappa</span></a>
            </li>
            -->
            <li class="nav-item">
                <a class="nav-link" [class.active]="tab==5" (click)="tab=5">
                    <i class="far fa-calendar-alt"></i><span class="d-none d-sm-inline-block ml-2">Calendario</span></a>
            </li>
            <li class="nav-item">
                <a class="nav-link" [class.active]="tab==2" (click)="tab=2">
                    <i class="fas fa-coins"></i><span class="d-none d-sm-inline-block ml-2">Pagamento rischedulato</span></a>
            </li>           
            <li class="nav-item">
                <a class="nav-link" [class.active]="tab==4" (click)="tab=4">
                    <i class="fas fa-chart-line"></i><span class="d-none d-sm-inline-block ml-2">Statistiche</span></a>
            </li>
        </ul> 
        <div class="tab-content" id="myTabContent" >
            <div class="tab-pane fade show active" *ngIf="tab==1">
                <app-activities-list   ></app-activities-list>
            </div>
            <div class="tab-pane fade show active" *ngIf="tab==2">
                <app-activities-reschedule-payment></app-activities-reschedule-payment>
            </div>
            <div class="tab-pane fade show active" *ngIf="tab==3">
                <app-activities-list [modeView]="2" ></app-activities-list>
            </div>
            <div class="tab-pane fade show active" *ngIf="tab==5">
                <app-activities-calendar  ></app-activities-calendar>
            </div>
            
            <div class="tab-pane fade show active" *ngIf="tab==4">
                <app-activities-analytics ></app-activities-analytics>
            </div>
        </div>
</section>