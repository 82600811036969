<app-windowlist [model]="this">
    <div class="row">
        <div class="col-lg-2 pr-md-0" *ngIf="filterbox">
            <app-filtercontainer [title]="'Filtra per date o sedi'">
                <app-filterdate [date_type]="mode!='embedded'?1:100" [class]="'card p-1'" [filter_date]="filter_date" (onSelect)="updateList()"></app-filterdate>
                <ul class="card small-box list-group p-1 my-2" *ngIf="user.isAdmin() && isModuleEnabled('shops')">
                    <li class="list-group-item pointer" [class.active]="filter_created_from.value==''" (click)="filter_created_from.value='';getItems()">Tutte le sedi</li>
                    <li class="list-group-item text-truncate pointer" *ngFor="let s of shops" [class.active]="filter_created_from.value==s.id" (click)="filter_created_from.value=s.id;getItems()">{{s.name}}</li>
                    <div  *ngIf="shops.length>2" class="icon"><i class="fa fa-store-alt "></i></div>
                </ul>
                <!-- <ul class="card small-box list-group p-1 mt-1" *ngIf="user.isAdmin()">
                    <li class="list-group-item" [class.active]="filter_financialaccount.value==''" (click)="filter_financialaccount.value='';getItems()">Tutti i conti contabili</li>
                    <li class="list-group-item" *ngFor="let f of financialaccounts" [class.active]="filter_financialaccount.value==f.id" (click)="filter_financialaccount.value=f.id;getItems()">{{f.name}}</li>
                </ul> -->
            </app-filtercontainer>
        </div>
        <div [class.col-lg-10]="filterbox" [class.col-lg-12]="!filterbox" >
            <div class="card card-outline">
                <ng-container *ngIf="filterbox">
                    <div class="row">
                        <div class="col-lg-5">
                            <div class="input-group input-group-sm m-1">
                                <input type="text" #table_search name="table_search" class="form-control float-right" placeholder="cerca..." [(ngModel)]="filter_search.value" (keyup.enter)="getItems()">
                                <div class="input-group-append ">
                                    <button type="submit" class="btn btn-default text-primary"  title="Avvia ricerca" (click)="getItems()"><i class="fas fa-search"></i></button>
                                    <button class="btn btn-default text-danger"   title="Annulla ricerca" *ngIf="filter_search.value!=''" (click)="filter_search.value='';getItems();"><span id="searchclear" class="fas fa-times"></span></button>
                                </div>
                            </div>
                        </div>
                        <div class="col-6 col-lg-3">
                            <div class="input-group input-group-sm m-1">
                                <select class="custom-select" [(ngModel)]="filter_id_user.value" (ngModelChange)="getItems()" [class.alert-info]="filter_id_user.value">
                                    <option value="">Tutti gli operatori</option>
                                    <ng-container *ngFor="let u of users">
                                        <option *ngIf="u.enabled" [ngValue]="u.id">{{u.username}}</option>
                                    </ng-container>
                                </select>
                            </div>
                        </div>
                        <div class="col-6 col-lg-4" >
                            <div class="pr-1">
                                <app-windowlistbuttons 
                                [model]="this" 
                                [table]="'cashregister'" 
                                [openDetailExtra]="{'name':'id_address','value':id_address}" 
                                [funs]="[]"
                                ></app-windowlistbuttons>
                            </div>                            
                            <!-- 
                            <button  title="Cestina" *ngIf="filter_status.value == '1'" (click)="trash()" [disabled]="form.value.id.length == 0" class="btn btn-danger btn-xs float-right mx-1 "><i class="far fa-trash-alt"></i></button>
                            <button  title="Elimina" *ngIf="filter_status.value == '2'" (click)="delete()" [disabled]="form.value.id.length == 0" class="btn btn-danger btn-xs float-right mx-1 "><i class="fas fa-trash"></i></button>
                            <button  title="Ripristina" *ngIf="filter_status.value == '2'" (click)="undo()" [disabled]="form.value.id.length == 0" class="btn btn-secondary btn-xs float-right ml-1 "><i class="fas fa-undo"></i></button>
                            <button  title="Esporta" (click)="export()"  class="btn btn-secondary btn-xs float-right ml-1 "><i class="fas fa-file-export"></i></button>
                            <button  title="Aggiungi" (click)="openDetail(null,{'name':'id_address','value':id_address})" class="btn btn-success btn-xs float-right ml-1"><i class="fas fa-plus"></i></button> -->
                        </div>
                    </div>
                </ng-container>

                <div *ngIf="!list || list.length==0" class="m-5 text-center text-secondary">
                    <i class="icon fas fa-info-circle mr-2"></i><i>Nessun elemento soddisfa i parametri di ricerca impostati!</i>

                </div>
                <form *ngIf="list && list.length>0" [formGroup]="form">
                    <table class="table table-nowrap table-striped table-sm table-head-fixed table-hover m-0 text-sm">
                        <thead class="bg-light">
                            <th>
                                <div #checkboxselectall class="icheck-primary d-inline mr-3">
                                    <input type="checkbox" title="Seleziona tutti"
                                    (change)="onChangeAll($event.target.checked)" />
                                </div>
                            </th>
                            <th>Data
                                <button type="button" class="btn btn-tool" (click)="switchOrdering('c.date')">
                                    <i class="fas fa-sort {{classOrdering('c.date')}}"  ></i>
                                </button>
                            </th>
                            <th>Descrizione
                                <button type="button" class="btn btn-tool" (click)="switchOrdering('c.description')">
                                    <i class="fas fa-sort {{classOrdering('c.description')}}"  ></i>
                                </button>
                            </th>
                            <th class="text-center">Calcolato
                                <button type="button" class="btn btn-tool" (click)="switchOrdering('c.amount')">
                                    <i class="fas fa-sort {{classOrdering('c.amount')}}"  ></i>
                                </button>
                            </th>
                            <th class="text-center">Reale
                                <button type="button" class="btn btn-tool" (click)="switchOrdering('c.paid')">
                                    <i class="fas fa-sort {{classOrdering('c.paid')}}"  ></i>
                                </button>
                            </th>
                            <th class="text-center">Utente</th>
                            <th></th>
                        </thead>
                        <tbody>
                        
                            <!-- elemento ripetuto -->
                            <tr *ngFor="let record of list" [class.bg-warning]="verifyDate(record.date)" [class.bg-redlight]="record.id_type==2">
                                <td  title="{{record.date | date:'EEEE'}}">
                                    <div class="icheck-primary d-inline mr-3">
                                        <input type="checkbox" #checkrecord id_record="{{record.id}}"
                                        (change)="onChange(record.id, $event.target.checked)" [checked]="checkedAll"
                                        />
                                    </div>
                                </td>
                                <td>
                                    <a [class.trashed]="record.status == 2" (click)="openDetail(record)">{{record.date | date:'dd/M/yyyy'}}</a>
                                </td>
                                <td>{{ record.description }}</td>
                                <td  class="text-center" [class.text-red]="record.amount < 0">{{ record.amount | currency:'EUR':true }}</td>
                                <td  class="text-center" [class.text-red]="record.paid < 0">{{ record.paid | currency:'EUR':true }}</td>
                                <td class="text-center">
                                    <small>
                                        <div class="text-truncate mw-100">{{record.username}}</div>
                                        <div *ngIf="record.shopname" class="badge border mw-100 text-truncate"><i class="fa-store-alt fas mr-1"></i><b>{{record.shopname}}</b></div>
                                    </small>
                                </td>
                                <td>
                                    <a *ngIf="mode!='modal'" (click)="openDetail(record)"  title="Modifica / Vedi" class="btn btn-secondary btn-xs float-right">
                                    <i class="fas fa-pencil-alt"></i></a>
                                    <button type="button"  *ngIf="mode=='modal'" class="btn btn-secondary btn-xs float-right" (click)="selectRecord(record);">
                                        <span class="d-none d-sm-inline-block m-1">Seleziona</span><i class="fas fa-chevron-right"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </form>
                <div class="card-footer clearfix py-1" *ngIf="mode!='embedded'">
                    <app-pagination [model]="this" ></app-pagination>
                </div>
            </div>
        </div>
    </div>
</app-windowlist>