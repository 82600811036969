<app-windowlist [model]="this">
    <div class="card">
        <div class="row">
            <div class="col-lg-5">
                <div class="input-group input-group-sm p-1">
                    <input type="text" #table_search name="table_search" class="form-control form-control-sm" placeholder="Cerca per nome o username ..." [(ngModel)]="filter_search.value" (keyup.enter)="getItems()">
                    <div class="input-group-append ">
                        <button type="submit" class="btn btn-default text-primary" title="Avvia ricerca" (click)="getItems()"><i class="fas fa-search"></i></button>
                        <button class="btn btn-default text-danger" title="Annulla ricerca" *ngIf="filter_search.value!=''" (click)="filter_search.value='';getItems();"><span id="searchclear" class="fas fa-times"></span></button>
                    </div>
                </div>
            </div>
            <div class="col-6 col-lg-2 p-1">
                <div class="input-group input-group-sm" >
                    <select class="form-control form-control-sm" [(ngModel)]="filter_role.value" (ngModelChange)="getItems()">
                        <option value="">Tutti i ruoli</option>
                        <option value="admin">Administrator</option>
                        <option value="manager">Manager</option>
                        <option value="agent">Agent</option>
                        <option value="external">Partner</option>
                        <option value="guest">Ospite</option>
                    </select>
                </div>
            </div>
            <div class="col-6 col-lg-2 p-1">
                <div class="input-group input-group-sm">
                    <select class="form-control form-control-sm"
                            [(ngModel)]="filter_status.value"
                            (ngModelChange)="getItems()" 
                            [class.text-orange]="filter_status.value=='0'" >
                        <option value="">Tutti</option>
                        <option value="1">Solo abilitati</option>
                        <option value="0">Solo disabilitati</option>
                    </select>
                </div>
            </div>
            <div class="col-lg-3">
                <button  title="Aggiungi" (click)="addUser()" class="btn btn-success btn-sm float-right mr-1 mt-1"><i class="fas fa-plus"></i></button>
                <button  title="Esporta" (click)="export()" class="btn btn-warning btn-sm float-right mr-1 mt-1"><i class="fas fa-print"></i></button>
            </div>
        </div>
        <form [formGroup]="form" *ngIf="list!=null">
            <table class="table table-sm table-striped text-nowrap m-0" >
                <thead>
                    <th>ID</th>
                    <th>Nome</th>
                    <th>Username</th>
                    <th>Info</th>
                    <th></th>
                </thead>
                <tbody>

                    <!-- elemento ripetuto -->
                    <ng-container *ngFor="let user of list">
                        <!-- <tr [class.text-gray]="!user.enabled" *ngIf="checkPermission(user)"> -->
                        <tr *ngIf="checkPermission(user)">
                            <td>
                                {{user.id}}
                            </td>
                            <td>
                                <i *ngIf="!user.enabled" title="Utente disabilitato" class="fas fa-user-slash mr-1 text-orange help"></i>
                                <ng-container *ngIf="user.enabled">
                                    <i *ngIf="user.role=='guest'" class="fas fa-user mr-1 text-gray"></i>                                
                                    <i *ngIf="user.role=='agent'|| user.role=='external'" class="fas fa-user-secret mr-1 text-pink"></i>
                                    <i *ngIf="user.role!='guest' && user.role!='agent' && user.role!='external'" class="fas fa-user-tie mr-1 text-primary"></i>
                                </ng-container>
                                {{user.name}}
                            </td>
                            <td>                               
                                {{user.username}}
                            </td>
                            <td>
                                <small>
                                    <i *ngIf="user.role=='admin'"       >Amministratore</i>
                                    <i *ngIf="user.role=='manager'"     >Manager</i>
                                    <i *ngIf="user.role=='agent'"       >Agente</i>
                                    <i *ngIf="user.role=='external'"    >Partner</i>
                                    <i *ngIf="user.role=='guest'"       >Ospite</i>
                                     dal {{user.created  | date:"d MMMM yyyy"}}
                                </small>
                            </td>
                            <td class="pr-2">
                                <ng-container  *ngIf="mode!='modal'">
                                    <button type="button" *ngIf="currentUser.isSuperUser()" title="Elimina" class="btn btn-danger btn-xs float-right ml-1" (click)="confirm_delete(user.id)">
                                        <i class="fas fa-trash-alt"></i></button>
                                    <button type="button"  class="btn btn-secondary btn-xs float-right ml-1" data-toggle="modal" data-target="#modalUser" (click)="setUser(user)">
                                        <i class="fas fa-pencil-alt"></i></button>
                                </ng-container>
                                <button type="button" *ngIf="mode=='modal'" class="btn btn-secondary btn-xs float-right" (click)="selectRecord(user);">
                                    <span class="d-none d-sm-inline-block m-1">Seleziona</span><i class="fas fa-chevron-right"></i>
                                </button>
                            </td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </form>
        <div class="card-footer clearfix py-1">
            <app-pagination [model]="this" ></app-pagination>
        </div>
    </div>
</app-windowlist>


<ng-template #modalUser>
        <div class="modal-header">
            <b>Impostazione utente</b>
        </div>
        <div class="modal-body" *ngIf="selectedUser">
            <div class="row">
                <div class="col-lg-12 mb-2">
                    <div class="form-group form-group-sm">
                        <label>Nome</label>
                        <input type="text" class="form-control form-control-sm" placeholder="..."  [(ngModel)]="selectedUser.name" >
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="form-group form-group-sm">
                        <label>Username <small>(Email ?)</small></label>
                        <div class="input-group input-group-sm">
                            <input type="email" class="form-control form-control-sm" placeholder="..."  [(ngModel)]="selectedUser.username" >
                            <div class="input-group-append input-group-append-sm">
                                <span class="input-group-text input-group-text-sm"><i class="fas fa-user"></i></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="form-group form-group-sm">
                        <label>Password</label>
                        <div class="input-group input-group-sm">
                            <input *ngIf="!currentUser.isSuperUser()" #pw type="text" class="form-control form-control-sm" placeholder="****" (change)="selectedUser.password=pw.value">
                            <input *ngIf="currentUser.isSuperUser()" class="form-control form-control-sm"  [(ngModel)]="selectedUser.password">
                            <div class="input-group-append input-group-append-sm">
                                <span class="input-group-text input-group-text-sm"><i class="fa fa-key"></i></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12">
                    <div class="form-group form-group-sm">
                        <label>Ruolo</label>
                        <select class="form-control form-control-sm" name="role" [(ngModel)]="selectedUser.role">
                            <option value="admin"   *ngIf="currentUser.isAdmin() || currentUser.isSuperUser()"  >Amministratore</option>
                            <option value="manager" *ngIf="currentUser.isAdmin() || currentUser.isSuperUser()"  >Manager</option>
                            <option value="agent"                                                               >Agente</option>
                            <option value="external"                                                            >Partner</option>
                            <option value="guest"                                                               >Ospite</option>
                        </select>
                        <small>
                            <i *ngIf="selectedUser.role=='admin'"     >*<b>Amministratore</b>: ha accesso a tutte le sezioni ed autorizzazioni complete.</i>
                            <i *ngIf="selectedUser.role=='manager'"   >*<b>Manager</b>: ha accesso solo alle sezioni assegnategli dall'Amministratore ed autorizzazioni complete.</i>
                            <i *ngIf="selectedUser.role=='agent'"     >*<b>Agente</b>: ha accesso solo alle sezioni assegnategli dall'Amministratore ed autorizzazioni limitate (visualizzazione, inserimento, modifica).</i>
                            <i *ngIf="selectedUser.role=='external'"  >*<b>Partner</b>: ha accesso limitato solo alle sezioni assegnategli dall'Amministratore ed autorizzazioni minime (visualizzazione, inserimento).</i>
                            <i *ngIf="selectedUser.role=='guest'"     >*<b>Ospite</b>: non ha accesso alla piattaforma di gestione ma solo eventualmente a quella riservata ai clienti registrati.</i>
                        </small>
                    </div>
                </div>
                <div class="col-lg-12">
                    <div class="form-group form-group-sm">
                        <label>Stato</label>
                        <select name="enabled" [(ngModel)]="selectedUser.enabled"
                                class="form-control form-control-sm" 
                                [class.text-orange]="selectedUser.enabled=='0'" >
                                <option value="1">Abilitato</option>
                                <option value="0">Disabilitato</option>
                        </select>
                    </div>
                </div>
                <div class="col-lg-12" *ngIf="currentUser.isSuperUser()">
                    <div class="form-group form-group-sm">
                        <label>ID Anagrafica</label>
                        <div class="input-group input-group-sm">
                            <input type="text" class="form-control form-control-sm" [(ngModel)]="selectedUser.id_address">
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="isArray(selectedUser.params)">
                <div  *ngFor="let p of selectedUser.params" [class.col-3]="p.type=='number' || p.type=='boolean'"  [class.col-4]="p.type=='text'" [class.col-12]="p.type=='table'">
                    <label>{{p.label}}</label>
                    <input *ngIf="p.type=='number'" type="number" class="form-control form-control-sm" name="value" [(ngModel)]="p.value" />
                    <input *ngIf="p.type=='text'" type="text" class="form-control form-control-sm" name="value" [(ngModel)]="p.value" />
                    <select *ngIf="p.type=='boolean'" class="form-control form-control-sm" name="value" [(ngModel)]="p.value">
                        <option value="true">Si</option>
                        <option value="false">No</option>
                    </select>
                    <ngx-tags-input *ngIf="p.type=='multiple'" displayField="item" class="form-control form-control-sm" [(ngModel)]="p.value" name="value"></ngx-tags-input>
                    <table *ngIf="p.type=='table'" class="table table-nowrap  table-sm table-head-fixed table-hover m-0 text-sm">
                        <thead class="bg-secondary">
                            <th *ngFor="let f of p.fields">{{f.label}}</th>
                            <th></th>
                        </thead>
                        <tbody>
                            <tr *ngFor="let v of p.value">
                                <td *ngFor="let f of p.fields">
                                    <input class="form-control form-control-sm" type="text" name="{{f.name}}" [(ngModel)]="v[f.name]"/>
                                </td>
                                <td>
                                    <button type="button" class="btn btn-xs btn-danger" (click)="removeTableRecord(p,v);"><i class="fa fa-trash"></i></button>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <td><button type="button" class="btn btn-xs btn-success" (click)="addTableRecord(p);"><i class="fa fa-plus"></i></button></td>
                            </tr>

                        </tfoot>
                    </table>
                </div>
            </div>
            <div class="row" *ngIf="user.role=='superuser'">
                <div class="col-12">
                    <label>Visualizzatore JSON</label>
                    <json-editor [data]="selectedUser.params" (change)="json_params=$event"></json-editor>
                </div>
                <div class="col-12">
                    <button class="btn btn-xs text-white" style="background-color: #3883fa;" (click)="selectedUser.params=json_params">Applica</button>                   
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-success"  (click)="confirmUser()"><i class="fas fa-save mr-2"></i>Conferma</button>
            <button type="button" class="btn btn-secondary" (click)="cancelUser()"  >Annulla</button>
        </div>
</ng-template>