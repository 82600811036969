import { NgModule } from '@angular/core';
import { ShopsComponent } from './shops.component';
import { ShopsDetailsComponent } from './shops-details/shops-details.component';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { WindowlistModule } from 'projects/core/src/common/windowlist/windowlist.module';
import { PaginationModule } from 'projects/core/src/common/pagination/pagination.module';
import { ShopsRoutingModule} from './shops-routing.module';
import { CitiesModule } from '../../modules/cities/cities.module';
import { ToolbarmodelModule } from 'projects/core/src/common/toolbarmodel/toolbarmodel.module';
//import { InstallationsModule } from '../installations/installations.module';
import { ContractsModule } from '../contracts/contracts.module';
import { SelectfieldcustomModule } from 'projects/core/src/common/selectfieldcustom/selectfieldcustom.module';
import { SearchgpsModule } from '../../modules/searchgps/searchgps.module';
import { MapComponent } from './map/map.component';
import { ListComponent } from './list/list.component';
import { DeadlinesModule } from '../deadlines/deadlines.module';
import { ActivitiesModule } from '../activities/activities.module';
import { AddressModule } from '../address/address.module';
import { CustomfieldsModule } from 'projects/core/src/common/customfields/customfields.module';
import { ProductsModule } from '../products/products.module';
import { WindowModule } from "../../../../../core/src/common/window/window.module";
import { WindowlistbuttonsModule } from 'projects/core/src/common/windowlistbuttons/windowlistbuttons.module';
import { TimesheetModule } from '../../modules/timesheet/timesheet.module';

@NgModule({
    exports: [ShopsComponent, ShopsDetailsComponent],
    declarations: [ShopsComponent, ShopsDetailsComponent, MapComponent, ListComponent],
    providers: [],
    imports: [
        FormsModule,
        CommonModule,
        CustomfieldsModule,
        WindowlistModule,
        ShopsRoutingModule,
        PaginationModule,
        CitiesModule,
        ToolbarmodelModule,
        ContractsModule,
        SelectfieldcustomModule,
        SearchgpsModule,
        DeadlinesModule,
        ActivitiesModule,
        ProductsModule,
        WindowModule,
        WindowlistbuttonsModule,
        TimesheetModule
    ]
})
export class ShopsModule{ }