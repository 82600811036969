<div>
    <div #componentHolder ></div>
</div>
<span *ngIf="references.length>0">
    <ng-container *ngFor="let ref of references">
       <ng-container *ngIf="ref">
        
           <div class="">
                <div class="px-1" [class.minimazed]="minimazed" >  <!-- <div [class.col-12]="!showDisconnect" [class.col-10]="showDisconnect"> -->
                    <!-- INSTALLAZIONE -->
                    <div *ngIf="ref.table_reference=='installations'">
                        <div class="badge bg-lightblue mb-1">Seriale</div>
                        <div class="ml-2">
                            <span class="d-inline-block mw-75 text-truncate border-bottom" [class.trashed]="ref.status==2">
                                <span (click)="openReference(ref,'installations')" title="Visualizza prodotto">
                                    <i *ngIf="ref.icon" class="{{ref.icon}} pointer"></i>
                                    <i *ngIf="!ref.icon" class="fa fa-cash-register pointer"></i>
                                </span>
                                <b class="mx-2" title="Marca">{{ref.brand}}</b>
                                <span class="mr-2" title="Modello">{{ref.model}}</span>
                                <small title="Seriale">{{ref.sn}}</small>
                            </span>
                            <small *ngIf="ref.status==2" class="badge badge-warning"><i class="fas fa-exclamation-triangle mr-2"></i>Cestinato</small>
                        </div>
                        <ng-container *ngIf="ref.shop">
                            <div class="ml-3" *ngIf="ref.shop.addressItem" >
                                <ng-container [ngTemplateOutlet]="shop" [ngTemplateOutletContext]="{record:ref.shop}"></ng-container>
                            </div>
                            <div class="ml-4">
                                <ng-container [ngTemplateOutlet]="address" [ngTemplateOutletContext]="{record:ref.shop.addressItem}"></ng-container>
                            </div>
                        </ng-container>
                        <div class="alert alert-info p-1  mt-1 mb-0 text-center" *ngIf="ref.contract && ref.contract.id>0">
                            <i class="fa-file-signature fas mr-2 pointer" title="Visualizza contratto" (click)="openReference(ref.contract,'contracts')"></i>
                            Prodotto con contratto <b>{{ref.contract.type}}</b>
                            <span class="ml-2" *ngIf="ref.contract.description">"{{ref.contract.description}}"</span><br>
                            <span class="badge" [class.badge-warning]="verifyDate(ref.contract.end)" [class.badge-danger]="!verifyDate(ref.contract.end)">Scadenza: {{ref.contract.end | date:"dd-MM-Y"}}</span>
                        </div>
                    </div>
                    <!-- ADDRESSES -->
                    <div *ngIf="ref.table_reference=='addresses'">
                        <div class="badge bg-indigo mb-1">Anagrafica</div>
                        <div class="ml-2">
                            <ng-container [ngTemplateOutlet]="address" [ngTemplateOutletContext]="{record:ref,strong:true}"></ng-container>
                        </div>
                    </div>
                    <!-- SHOP -->
                    <div *ngIf="ref.table_reference=='shops'">
                        <div class="badge bg-purple mb-1">Esercizio</div>
                        <div class="ml-2">
                            <ng-container [ngTemplateOutlet]="shop" [ngTemplateOutletContext]="{record:ref,strong:true}"></ng-container>
                        </div>
                        <div class="ml-3" *ngIf="ref.addressItem">
                            <ng-container [ngTemplateOutlet]="address" [ngTemplateOutletContext]="{record:ref.addressItem}"></ng-container>
                        </div>
                        <div class="alert alert-info p-1 mt-1 mb-0 text-center" *ngIf="ref.contract && ref.contract.id>0">
                            <i class="fa-file-signature fas mr-2 pointer" title="Visualizza contratto" (click)="openReference(ref.contract,'contracts')"></i>
                            <span class="help" title="{{ref.contract.type}}">Prodotto con contratto <b>{{ref.contract.type}}</b></span>
                            <span class="ml-2 help" *ngIf="ref.contract.description" title="{{ref.contract.description}}">"{{ref.contract.description}}"</span>
                            <br>
                            <span class="badge help" [class.badge-warning]="verifyDate(ref.contract.end)" [class.badge-danger]="!verifyDate(ref.contract.end)" title="Scadenza: {{ref.contract.end | date:'dd-MM-Y'}}">Scadenza: {{ref.contract.end | date:"dd-MM-Y"}}</span>
                        </div>
                    </div>
                    <!-- CONTRACTS -->
                    <div *ngIf="ref.table_reference=='contracts'">
                        <div class="badge bg-navy mb-1">Contratto</div>
                        <div class="ml-2">
                            <span class="d-inline-block mw-75 text-truncate border-bottom" [class.trashed]="ref.status==2">
                                <span (click)="openReference(ref,'contracts')" title="Visualizza contratto">
                                    <i class="fa-file-signature fas mr-2 pointer"></i>
                                </span>
                                <b class="mr-2">{{ref.type}}</b>
                                <span *ngIf="ref.description" class="help" title="{{ref.description}} dal {{ref.start | date:'dd-MM-YYYY'}} al {{ref.end | date:'dd-MM-YYYY'}}">"{{ref.description}}" dal {{ref.start | date:"dd-MM-YYYY"}} al {{ref.end | date:"dd-MM-YYYY"}}</span>
                            </span>
                        </div>
                        <div class="ml-3" *ngIf="ref.shop">
                            <ng-container [ngTemplateOutlet]="shop" [ngTemplateOutletContext]="{record:ref.shop}"></ng-container>
                        </div>
                        <div class="ml-4" *ngIf="ref.shop && ref.shop.addressItem">
                            <ng-container [ngTemplateOutlet]="address" [ngTemplateOutletContext]="{record:ref.shop.addressItem}"></ng-container>
                        </div>
                    </div>
                    <!-- CONTRACTS INSTALLMENTS -->
                    <div *ngIf="ref.table_reference=='contractsinstallments'">
                        <div class="badge bg-navy mb-1">Rata contratto</div>
                        <div class="widget-heading">
                            <div class="badge border pointer"><a (click)="openReference(ref,'contracts')"  title="Visualizza rata contratto"><i class="fas fa-file-signature"></i></a></div>
                            <strong [class.trashed]="ref.status==2">Contratto di <i>{{ref.type}} #{{ref.id_contract}}</i> rata n°<i>{{ref.number}}</i> <div *ngIf="ref.status==2" class="badge badge-warning"><i class="fas fa-exclamation-triangle"></i>&nbsp;cestinato</div></strong>
                        </div>
                        <div class="widget-subheading">
                            <ng-container [ngTemplateOutlet]="shop" [ngTemplateOutletContext]="{record:ref.shop}"></ng-container>
                        </div>
                    </div>
                    <!-- BOOKING -->
                    <div *ngIf="ref.table_reference=='booking'">
                        <div class="badge bg-pink mb-1">Prenotazione</div>
                        <div class="ml-2 pointer" (click)="openReference(ref,'booking')"  title="Vedi prenotazione"  title="Visualizza prenotazione">
                            <span class="d-inline-block mw-75 text-truncate border-bottom">
                                <i class="fas fa-file-signature mr-2"></i>
                                <span class="mr-2">Prenotazione</span><b>#{{ref.code}}</b>
                            </span>
                            <small *ngIf="ref.status==2" class="badge badge-warning"><i class="fas fa-exclamation-triangle mr-2"></i>Cestinato</small>
                        </div>
                    </div>
                    <!-- DOCUMENTS -->
                    <div *ngIf="ref.table_reference=='documents' && ref.addressItem">
                        <div class="mb-1">
                            <div class="badge badge-left border bg-lightblue">Documento</div>
                            <div class="badge badge-right border pointer" (click)="openReference(ref,'documents')" title="Visualizza documento"><b>{{getTypeDocument(ref.typedocument)}}</b></div>
                        </div>
                        <div class="ml-2">
                            <span class="d-inline-block mw-75 text-truncate">
                                <small title="{{ref.addressItem.name}}">{{ref.addressItem.name}}</small>
                            </span>
                            <br>
                            <span class="d-inline-block mw-75 text-truncate border-bottom" [class.trashed]="ref.status==2">
                                <span>n. <b title="{{ref.reference}}{{ref.referenceprefix}} del {{ref.date | date:'dd/MM/yyyy'}}">{{ref.reference}}<span *ngIf="ref.referenceprefix">/{{ref.referenceprefix}}</span></b> del <b>{{ref.date | date:"dd/MM/yyyy"}}</b></span>
                            </span>
                            <small class="badge border" [class.trashed]="ref.status==2"><b>{{ref.total | currency:"&euro; "}}</b></small>
                            <small *ngIf="ref.status==2" class="badge badge-warning"><i class="fas fa-exclamation-triangle mr-2"></i>Cestinato</small>
                            <div class="d-block mw-75 help text-truncate" title="{{ref.description2}}">
                                <small><i>{{ref.description}}</i></small>
                            </div>
                        </div>
                    </div>
                    <!-- TICKET -->
                    <div *ngIf="ref.table_reference=='tickets'">
                        <div class="badge badge-pink mb-1">Biglietto</div>
                        <div class="ml-2 pointer" (click)="openReference(ref,'tickets')" title="Visualizza biglietto">
                            <span class="d-inline-block mw-100 text-truncate border-bottom" [class.trashed]="ref.status==2">
                                <span [class.trashed]="ref.status==2">
                                    <ng-container *ngFor="let t of ref.tickets">
                                        <i>da {{t.port_from}} a{{t.port_to}} - {{t.date | date:"d/M/Y"}}</i> 
                                    </ng-container>
                                </span>
                            </span>
                            <small *ngIf="ref.status==2" class="badge badge-warning"><i class="fas fa-exclamation-triangle mr-2"></i>Cestinato</small>
                        </div>
                    </div>
                    <!-- ASSISTANCE -->
                    <div *ngIf="ref.table_reference=='assistances'">
                        <div class="badge bg-lightblue mb-1">Assistenza</div>
                        <div class="ml-2">
                            <span class="d-inline-block mw-75 text-truncate border-bottom" [class.trashed]="ref.status==2" >
                                <i class="fas fa-file pointer" (click)="openReference(ref,'assistances')"  title="Visualizza assistenza"></i>
                                <strong [class.trashed]="ref.status==2"><i> num {{ref.code}} del {{ref.date | date:"dd/MM/yyyy"}}</i> <div *ngIf="ref.status==2" class="badge badge-warning"><i class="fas fa-exclamation-triangle"></i>&nbsp;cestinato</div></strong>
                            </span>
                            <small *ngIf="ref.status==2" class="badge badge-warning"><i class="fas fa-exclamation-triangle mr-2"></i>Cestinato</small>
                        </div>
                    </div>
                    <!-- ACTIVITY -->
                    <div *ngIf="ref.table_reference=='activities'">
                        <div class="badge bg-orange text-white mb-1">Attività</div>
                        <div class="ml-2" >
                            <span class="d-inline-block mw-75 text-truncate border-bottom" [class.trashed]="ref.status==2">
                                <i class="fa-tasks fas mr-2 pointer" (click)="openReference(ref,'activities')"  title="Visualizza attività"></i>
                                <b>{{ref.name}}</b><br/>
                                <small>
                                <b *ngIf="ref.type" class="help" title="{{ref.date | date:'dd-MM-YY'}} - {{ref.type.name}}{{ref.type}} - {{ref.description}}">
                                    {{ref.date | date:"dd-MM-YY"}} - 
                                    <span *ngIf="ref.type.name">{{ref.type.name}}</span>
                                    <span *ngIf="!ref.type.name">{{ref.type}}</span> - 
                                     {{ref.description}}
                                </b>
                                </small>
                            </span>
                        </div>
                        <div class="ml-3" *ngIf="ref.reference">
                            <small>Riferita a:</small>
                            <app-reference [reference]="ref.reference" [table]="ref.reference.table_reference" ></app-reference>
                        </div>

                    </div>
                    <!-- DEADLINES -->
                    <div *ngIf="ref.table_reference=='deadlines'">
                        <div class="badge bg-orange text-white mb-1">Scadenza</div>
                        <div class="ml-2 pointer" (click)="openReference(ref,'deadlines')" title="Visualizza scadenza">
                            <div class="badge border"><i class="fa-tasks fas"></i></div>
                            <b>{{ref.type}}</b>
                        </div>
                        <small *ngIf="ref.status==2" class="badge badge-warning"><i class="fas fa-exclamation-triangle mr-2"></i>Cestinato</small>
                    </div>
                    <!-- PROJECTS -->
                    <div *ngIf="ref.table_reference=='project'">
                        <div class="badge bg-maroon mb-1">Progetto</div>
                        <div class="ml-2 pointer" (click)="openReference(ref,'project')"  title="Visualizza progetto">
                            <i class="fas fa-file mr-2"></i>
                            <b [class.trashed]="ref.status==2"><i>{{ref.name}} - {{ref.addressItem.name}}</i></b>
                        </div>
                        <small *ngIf="ref.status==2" class="badge badge-warning"><i class="fas fa-exclamation-triangle mr-2"></i>Cestinato</small>
                    </div>
                    <!-- ADS -->
                    <div *ngIf="ref.table_reference=='ads'">
                        <div class="badge bg-maroon mb-1">Annuncio</div>
                        <div class="ml-2 pointer" (click)="openReference(ref,'ads')"  title="Visualizza annuncio">
                            <i class="fas fa-file mr-2"></i>
                            <b [class.trashed]="ref.status==2"><i> {{ref.code}} - {{ref.title}}</i></b>
                        </div>
                        <small *ngIf="ref.status==2" class="badge badge-warning"><i class="fas fa-exclamation-triangle mr-2"></i>Cestinato</small>
                    </div>
                    <!-- DOSSIER -->
                    <div *ngIf="ref.table_reference=='dossier'">
                        <div class="badge bg-maroon mb-1">Pratica</div>
                        <div class="ml-2 pointer" (click)="openReference(ref,'dossier')"  title="Visualizza pratica">
                            <i class="fas fa-file mr-2"></i>
                            <b [class.trashed]="ref.status==2">
                                <i> {{ref.code}} - {{ref.addressItem.name}}</i><br/>
                                <small>{{ref.title}}</small>
                            </b>
                        </div>
                        <small *ngIf="ref.status==2" class="badge badge-warning"><i class="fas fa-exclamation-triangle mr-2"></i>Cestinato</small>
                    </div>
                </div>
            </div>
        </ng-container>
    </ng-container>
</span>


<ng-container *ngIf="mode=='detail'">
    <div class="m-5 text-center text-secondary" *ngIf="reference==undefined">
        <i class="fas fa-info-circle mr-2"></i>
        <i>Nessun elemento selezionato!</i>
    </div>
</ng-container>


<ng-container *ngIf="showConnectBtn">
    <div class="dropdown mt-1">
        <ng-container *ngIf="tables_to_connected.length==1; else elseBlock">
            <button [class.btn-block]="references.length==0" (click)="connectActivity(tables_to_connected[0].table)" class="btn btn-sm btn-secondary" type="button">
                <i class="fas fa-link mr-2"></i>Collega a {{tables_to_connected[0].label}}
            </button>
        </ng-container>
        <ng-template #elseBlock>
            <button [class.btn-block]="references.length==0" class="btn btn-sm btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class="fas fa-link mr-2"></i>Collega
            </button>
        </ng-template>
        
        <a *ngIf="references.length>0 && showDisconnect" class="btn btn-danger btn-sm" (click)="removeReference(ref);"
            style="margin-left: -0.15rem; border-top-left-radius: 0; border-bottom-left-radius: 0;" ><i class="fa fa-unlink"></i></a>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <ng-container *ngFor="let t of tables_to_connected">
                <a class="dropdown-item pointer" (click)="connectActivity(t.table)">{{t.label}}</a>
            </ng-container>
        </div>
    </div>
</ng-container>


<ng-template #address let-record="record" let-strong="strong">
    <span class="d-inline-block mw-75 text-truncate border-bottom" [class.font-weight-bold]="strong" [class.trashed]="record.status==2">  <!-- strong? -->
        <span (click)="openReference(record,'addresses')" title="Visualizza anagrafica"><i class="fa fa-user pointer"></i></span>
        <span title="{{record.name}}" class="ml-2 help">{{record.name}}</span>
    </span>
    <small class="badge border" *ngIf="record.balance!=0" [class.text-red]="record.balance < 0" [class.text-success]="record.balance > 0">{{record.balance | currency: "&euro; "}}</small>
    <small *ngIf="record.status==2" class="badge badge-warning"><i class="fas fa-exclamation-triangle mr-2"></i>Cestinato</small>
    <small *ngIf="record.contacts.length==0" class="badge border"><i class="fas fa-exclamation-triangle text-warning mr-2"></i>Nessun contatto</small>
</ng-template>


<ng-template #shop let-record="record" let-strong="strong">
    <span class="d-inline-block mw-75 text-truncate help border-bottom" [class.font-weight-bold]="strong" [class.trashed]="record.status==2"
         title="{{record.city | uppercase}} {{record.country | uppercase}} ({{record.latitude}}° - {{record.longitude}}°)">
        <span (click)="openReference(record,'shops')" title="Visualizza sede" class="pointer"><i class="fas fa-store"></i></span>
        <span class="ml-2">{{record.name}} <ng-container *ngIf="record.closed_day">&nbsp;<small class="text-danger">(chiuso {{record.closed_day}})</small></ng-container></span>
    </span>
    <small class="badge badge-warning" *ngIf="record.latitude==0">NO GPS</small>
    <small *ngIf="record.status==2" class="badge badge-warning"><i class="fas fa-exclamation-triangle mr-2"></i>Cestinato</small>
    <small class="badge border" *ngIf="record.type_params!='default'">{{record.type}}</small>
</ng-template>