import { Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
  selector: 'app-timesheet',
  templateUrl: './timesheet.component.html',
  styleUrl: './timesheet.component.css'
})
export class TimesheetComponent implements OnInit,OnChanges {

  @Input()
  daynumber=-1;

  @Input()
  edit=true;

  @Input()
  fieldname="";

  @Input()
  record:any=[]

  ngOnInit():void{
    setTimeout(()=>{

   
   
    if(typeof(this.record[this.fieldname])=="string"){
     
      try{
        this.record[this.fieldname]=JSON.parse(this.record[this.fieldname]);
      }catch{
        //this.newTimesheet();
      }
    }

  },2000);
  }

  ngOnChanges(): void {
    console.log(this.record[this.fieldname]);
  }



  fill(timesheet){
    for(let t of this.record[this.fieldname]){
      t.from1=timesheet.from1;
      t.from2=timesheet.from2;
      t.to1=timesheet.to1;
      t.to2=timesheet.to2;
      
    }
  }

  erase(timesheet){
    timesheet.from1="";
    timesheet.to1="";
    timesheet.from2="";
    timesheet.to2="";
    
    
  }
  newTimesheet(){
    this.record[this.fieldname]=[
      {"day":0,"from1":"","to1":"","from2":"","to2":""},
      {"day":1,"from1":"","to1":"","from2":"","to2":""},
      {"day":2,"from1":"","to1":"","from2":"","to2":""},
      {"day":3,"from1":"","to1":"","from2":"","to2":""},
      {"day":4,"from1":"","to1":"","from2":"","to2":""},
      {"day":5,"from1":"","to1":"","from2":"","to2":""},
      {"day":6,"from1":"","to1":"","from2":"","to2":""},
      
      
    ]
  }

  getDate(day){
    switch(day){
      case 0:
        return "Domenica";
      case 1:
        return "Lunedì";
      case 2:
        return "Martedì";
      case 3:
        return "Mercoledì";
      case 4:
        return "Giovedì";
      case 5:
        return "Venerdì";
      case 6:
        return "Sabato";
    }
  }

}
