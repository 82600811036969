import { EventEmitter, NgModule } from '@angular/core';

import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { PreviewnotificationModule } from '../../modules/previewnotification/previewnotification.module';
import { WindowlistModule } from 'projects/core/src/common/windowlist/windowlist.module';
import { CitiesModule } from '../../modules/cities/cities.module';
import { PaginationModule } from 'projects/core/src/common/pagination/pagination.module';
import { AdvancedsearchModule } from '../../modules/advancedsearch/advancedsearch.module';
import { InventoriesRoutingModule } from './inventories-routing.module';
import { ContactModule } from '../../modules/contact/contact.module';
import { InventoriesComponent } from './inventories.component';
import { InventoriesDetailsComponent } from './inventories-details/inventories-details.component';
import { InventoryoutComponent } from './inventoryout/inventoryout.component';
import { ListinventoriesComponent } from './listinventories/listinventories.component';
import { LoadserialsComponent } from './loadserials/loadserials.component';
import { LoginventoryComponent } from './loginventory/loginventory.component';
import { LogstockComponent } from './logstock/logstock.component';
import { ShowserialsComponent } from './showserials/showserials.component';
import { ModalModule } from 'projects/core/src/common/modal/modal.module';
import { SearchproductModule } from '../../modules/searchproduct/searchproduct.module';
import { QrcodereaderModule } from 'projects/core/src/common/qrcodereader/qrcodereader.module';
import { ToolbarmodelModule } from 'projects/core/src/common/toolbarmodel/toolbarmodel.module';
import { ComboboxModule } from 'projects/core/src/common/combobox/combobox.module';
import { WindowModule } from 'projects/core/src/common/window/window.module';
import { SelectfieldcustomModule } from 'projects/core/src/common/selectfieldcustom/selectfieldcustom.module';
import { TreecategoriesModule } from '../../modules/treecategories/treecategories.module';
import { FilterdateModule } from '../../modules/filterdate/filterdate.module';
import { ProductitemModule } from '../../modules/productitem/productitem.module';
import { SalesComponent } from './sales/sales.component';
import { MethodpaymentComponent } from './methodpayment/methodpayment.component';
import { GoodsModule } from '../../modules/goods/goods.module';
import { DocumentsModule } from '../documents/documents.module';
import { LoadComponent } from './load/load.component';
import { MoveinventoryModule } from '../../modules/moveinventory/moveinventory.module';
import { StateinventoriesComponent } from './stateinventories/stateinventories.component';
import { ReferenceModule } from '../../modules/reference/reference.module';
import { CustomfieldsModule } from 'projects/core/src/common/customfields/customfields.module';
import { HistoryComponent } from './history/history.component';
import { HistorystockComponent } from './historystock/historystock.component';
import { NgChartsModule } from 'ng2-charts';
import { ReceiptModule } from '../../modules/receipt/receipt.module';
import { KeyboardModule } from '../../modules/keyboard/keyboard.module';
import { AnalyticsComponent } from './analytics/analytics.component';
import { Globals } from 'projects/core/src/globals';
import { FiltercontainerModule } from '../../modules/filtercontainer/filtercontainer.module';
import { WindowlistbuttonsModule } from 'projects/core/src/common/windowlistbuttons/windowlistbuttons.module';



@NgModule({
    imports: [
        FormsModule,
        CommonModule,
        PreviewnotificationModule,
        CustomfieldsModule,
        WindowlistModule,
        WindowModule,
        CitiesModule,
        PaginationModule,
        AdvancedsearchModule,
        InventoriesRoutingModule,
        ContactModule,
        ModalModule,
        SelectfieldcustomModule,
        QrcodereaderModule,
        ToolbarmodelModule,
        ComboboxModule,
        TreecategoriesModule,
        FilterdateModule,
        GoodsModule,
        SearchproductModule,
        ProductitemModule,
        DocumentsModule,
        SelectfieldcustomModule,
        ReferenceModule,
        NgChartsModule,
        ReceiptModule,
        KeyboardModule,
        FiltercontainerModule,
        WindowlistbuttonsModule

    ],
   exports: [InventoriesComponent,InventoriesDetailsComponent,LoadserialsComponent,SalesComponent,LoginventoryComponent,MoveinventoryModule,HistoryComponent],
   declarations: [InventoriesComponent,InventoriesDetailsComponent,InventoryoutComponent,ListinventoriesComponent,LoadserialsComponent,LoginventoryComponent,LogstockComponent,ShowserialsComponent,SalesComponent,MethodpaymentComponent, LoadComponent, StateinventoriesComponent, HistoryComponent, HistorystockComponent, AnalyticsComponent],
   providers: [],
})
export class InventoriesModule{ 

    static savingInventoriesFastEvent:EventEmitter<void>=new EventEmitter();
    constructor(){
        Globals.parameters.loadModulesEvent.subscribe((result)=>{
            Globals.parameters.createParam(
                "sales",
                "c1connector_enable_receipt",
                "Abilita lo scontrino tramite C1 Connector",
                "boolean"
            );

            Globals.parameters.createParam(
                "inventories",
                "default_inventory",
                "ID Magazzino di default",
                "number"
            );

            Globals.parameters.createParam(
                "inventories",
                "force_unload_default_stock",
                "Non modificare le quantità nel magazzino principale",
                "boolean"
            );

            Globals.parameters.createParam(
                "inventories",
                "causals_move",
                "Causali di movimenti manuali",
                "table",
                [
                    {"name":"causal","label":"Causale"}
                ]
            );



            Globals.parameters.createParam(
                "salesbackend",
                "id_product_rep",
                "IDs prodotti Reparto",
                "text"
            );

            Globals.parameters.createParam(
                "salesbackend",
                "force_unload",
                "scarica anche prodotti con giacenza 0",
                "boolean"
            );

            Globals.parameters.createParam(
                "salesbackend",
                "groupProducts",
                "raggruppa i prodotti per quantità",
                "boolean"
            );


            Globals.parameters.createParam(
                "sales",
                "showKeyboard",
                "Mostra la tastiera",
                "boolean"
            );

            Globals.parameters.createParam(
                "salesbackend",
                "tax_default",
                "IVA di default",
                "number",
                22
            );

            Globals.addSection("products","history");
            Globals.addSection("products","logstock");
            Globals.addSection("products","loginventory");

            Globals.addSection("inventories","analytics");
            
            
            
        });

        
    }

}