import { Component, OnInit, Input, Output } from '@angular/core';
import { ModelList } from 'projects/core/src/include/modellist'
import { UntypedFormBuilder, FormGroup, FormArray, FormControl } from '@angular/forms';
import { TypesService } from '../../services/types.service';
import { Type } from '../../db/type';
import { Filter, FilterMode } from 'projects/core/src/include/structures'
import { Globals } from 'projects/core/src/globals';
import { AddressesService } from '../../services/addresses.service';
import { Address } from '../../db/address';
import { NotificationService } from '../../services/notification.service';
import { AddressDetailsComponent } from './address-details/address-details.component';


@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.sass']
})

export class AddressComponent extends ModelList<Address> implements OnInit {

  modulename="address";
  selected:number;
  type_address:Type[];
  status_address:Type[];
  filter_status:Filter=new Filter();
  filter_search:Filter=new Filter();
  filter_search_contact:Filter=new Filter();
  filter_id_type:Filter<number>=new Filter<number>();
  listmode=false;
  
  @Input()
  id_type:number=0;

  get currentUser(){
    return Globals.user;
  }
  
  constructor(
    private addressesService: AddressesService,
    private fb1: UntypedFormBuilder,
    private typesService: TypesService,
    private notificationService:NotificationService
    
  ) {
    super(addressesService,fb1);
    this.detailViewModal=AddressDetailsComponent;
   }


  ngOnInit(): void {
    this.title='Anagrafiche';
    super.ngOnInit.apply(this, arguments);
   
    
    this.typesService.getTypes("addresses").subscribe((items)=>{
      this.type_address=items;

        this.filter_search_contact.enabled=false;      
        this.filter_id_type.mode=FilterMode.normal;
        this.filter_id_type.fields=[];
        this.filter_id_type.fields.push("a.id_type");
        this.filter_id_type.nullvalue=0;
        if(this.id_type>0){
          this.filter_id_type.value=this.id_type;
        }else{
          this.filter_id_type.value=this.type_address.filter(t=>t.default==1).length>0?this.type_address.filter(t=>t.default==1)[0].id:0
        }
        this.filter.push(this.filter_id_type);


        this.filter_search.mode=FilterMode.like;
        this.filter_search.fields=[];
        this.filter_search.fields.push("a.name");
        this.filter_search.fields.push("a.address");
        this.filter_search.fields.push("a.city");
        this.filter_search.fields.push("a.vat_number");
        this.filter_search.fields.push("a.tax_code");
        this.filter_search.fields.push("a.sdi");
        this.filter_search.fields.push("a.pec");

        this.filter_search.fields.push("s.name");
        this.filter_search.field_fulltext="a.name";
       
        
        this.filter_search.value="";
        this.filter.push(this.filter_search);


        this.beforeGetItems=(()=>{
          //verifica se si vuole filtrare anche per contatti
          if(this.filter_search_contact.enabled){
            if(this.filter_search.fields.length<7)
              this.filter_search.fields.push("(SELECT GROUP_CONCAT(DISTINCT(value)) as value FROM contacts WHERE id_table=a.id AND `table`='addresses')");
          }else{
            if(this.filter_search.fields.length==7)
              this.filter_search.fields.pop();
          }
        });
        

        this.filter_status.mode=FilterMode.normal;
        this.filter_status.fields=[];
        this.filter_status.fields.push("a.status");
        this.filter_status.value="1";
        this.filter.push(this.filter_status);
      

        if (this.module.getParam("mode_view",0)==1) {
          this.listmode=true;
          this.ordering.field="a.name";
          this.ordering.mode="ASC";
        } else {
          this.listmode=false;
          this.ordering.field="a.created";
          this.ordering.mode="DESC";
        }
      
      //verifica se ci sono dei filtri già impostati
      this.advancedFilter=Globals.navigation.getStateFilters(this.constructor.name,this.filter);
     
      this.getItems(null,true,true);
      
  });
    
  }

  
  notify(record:Address){   
    this.notificationService.showPreviewNotification(record.contacts,record,"addresses",()=>{

    });
    
  }


  joinAddresses(){

    // if(confirm("Confermi di voler unire i record selezionati?")){
    //   //prendi gli id degli address
    //   this.addressesService.joinAddresses(this.form.value.id).subscribe(()=>{
    //     this.getItems();
    //   })
    // }

    Globals.modal.showConfirm("Confermi di voler unire le anagrafiche selezionate?","",()=>{
        this.addressesService.joinAddresses(this.form.value.id).subscribe(()=>{
          this.getItems();
        })
    },"Sì, unisci","No. Grazie")

  }

  
}
